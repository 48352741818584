.home,
.about,
.projects,
.contact {
  min-height: 30vh;
  text-align: center;
}

*{
  padding: 0;
  margin: 0;
}

h1 {
  color: white;
}

body {
  font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif;
}

.spacer {
  aspect-ratio: 960/300;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.layer1 {
  background-image: url('C:\Users\Oscar\Documents\portfolio\public\svg\layer1-2.svg');
}

.layer2 {
  background-image: url('C:\Users\Oscar\Documents\portfolio\public\svg\layer2-2.svg');
}

#home {
  background: #001220;
}

.about {
  /* gree background 
  background: #dff3e3;
  */
  background: #001220;
  height: 300px;
  padding-bottom: 100px;
}

.projects {
  background: #5d1e38;
}
.contact {
  background: #312965;
}

.sticky {
  position: sticky;
  top: 0;
}

@media (max-width: 650px) {
  .layer1 {
    padding-top: 500px;
  }

  .layer2 {
    padding-top: 300px;
  }
}

@media screen and (min-width: 768px) {
  .nav-center a:not(:last-of-type) {
    margin-right: 0.5rem;
  }
}
@media  only screen and (max-width: 768px) {
  .about {
    height: 380px;
  }

  html, body {
    overflow-x: hidden;
  }
}