
.contact-list {
    width: 100%;
}

.contact-list ul {
    overflow: hidden;
}

/* remove list bullet points */
.contact-list ul li {
    list-style-type: none;
}
/* center text inside contact card */
.contact-list p {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-list .glass-panel {
    color: #fff;
    margin: 40px auto;
    margin-right: 40px;
    background-color: rgba(255,255,255,0.06);
    border: 1px solid rgba(255,255,255,0.1);
    width: 7%;
    border-radius: 15px;
    padding: 32px;
    backdrop-filter: blur(10px);
    display: inline-block; /* turn list horizontal */
  }

  .contact-list img {
      height: 80%;
      width: 80%;
  }

  @media  only screen and (max-width: 768px) {
    .contact-list .glass-panel {
        display: flex;
        justify-content: center;
        margin-right: auto;
        width: 30%;
    }

}