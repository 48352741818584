.navbar {
    background: var(--white);
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
    z-index: 9; /* navigation bar stays on top of other content */
  }
  .navbar img {
    width: clamp(125px, 30vw, 200px);
    margin-bottom: -2px;
  }
  .nav-center {
    width: 100%;
    max-width: var(--max-width);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .navbar h1 {
    color: white;
  }
  .nav-center a {
    font-size: clamp(0.25rem, 3vw, 0.75rem);
    color: white;
    transition: var(--transition);
    text-transform: uppercase;
    padding: 5px;
  }

  .nav-center a:not(:last-of-type) {
    margin-right: 0.25rem;
  }
