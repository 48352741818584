.project-list {
    width: 100%;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
}

.project-list ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
}

.project-list li {
    padding: 10px;
    list-style-type: none;
    margin: auto;
    width: 85%;
    height: auto;
    
}

.project-list .glass-panel {
    color: #fff;
    margin: 40px auto;
    background-color: rgba(255,255,255,0.06);
    border: 1px solid rgba(255,255,255,0.1);
    width: 85%;
    border-radius: 15px;
    padding: 32px;
    backdrop-filter: blur(10px);
  }

  .glass-button {
    display: inline-block;
    padding: 24px 32px;
    border: 0;
    text-decoration: none;
    border-radius: 15px;
    background-color: rgba(255,255,255,0.1);
    border: 1px solid rgba(255,255,255,0.1);
    backdrop-filter: blur(30px);
    color: rgba(255,255,255,0.8);
    font-size: 14px;
    letter-spacing: 2px;
    cursor: pointer;
    text-transform: uppercase;
  }

  .glass-button:hover {
    background-color: rgba(255,255,255,0.2);
  }


.project-list img {
    height: 80%;
    width: 100%;
    border-radius: 10px;
}

.project-list li ul p h3 {
    color: white;
}

.project-list p {
   overflow: hidden;
}

/*change grid project list to show 1 by 1 in mobile*/

@media  only screen and (max-width: 768px) {
    .project-list ul {
        display: grid;
        grid-template-columns: 1fr;
    }

    .project-list li {
        width: 80%; /* on mobile, card width takes all width */
        margin: auto;
    }

    .project-list .glass-panel {
        width: 70%;
    }
}

@keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}