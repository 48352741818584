.hobby-list {
    width: 100%;
    overflow: hidden; /* hide whitespace on bottom of page */
}

.hobby-list img {
    height: 250px;
    width: 250px;
    border-radius: 10px;
    object-fit: cover; /* img style fill */
}

.hobby-list .glass-panel {
    color: #fff;
    margin: 40px auto;
    margin-right: 40px;
    background-color: rgba(255,255,255,0.06);
    border: 1px solid rgba(255,255,255,0.1);
    width: 20%;
    border-radius: 15px;
    padding: 32px;
    backdrop-filter: blur(10px);
    display: inline-block; /* turn list horizontal */
  }

  .hobby-list h3 {
      color: white;
  }

  @media  only screen and (max-width: 768px) {
    .hobby-list img {
        width: 200px;
        height: 200px;
    }
    .hobby-list .glass-panel {
        display: flex;
        justify-content: center;
        margin-right: auto;
        width: 50%;
    }
}
    @media  only screen and (max-width: 968px) {
        .hobby-list img {
            width: 150px;
            height: 150px;
        }
}
/*
    @media only screen and (max-width: 500px) {
        .hobby-list img {
            width: 77px;
            height: 77px;
        }
    } */
