.About {
    overflow: hidden;
}


.profile {
    display: block;
    margin-left: auto;
    width: 327px;
}

.home-section {
    max-width: 1234px;
    margin: 0 auto;
    padding: 200px 30px;
    display: grid;
    grid-template-columns: 360px auto;

    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 1s; /* Firefox < 16 */
     -ms-animation: fadein 1s; /* Internet Explorer */
      -o-animation: fadein 1s; /* Opera < 12.1 */
         animation: fadein 1s;
  
}

.intro-text {
    max-width: 360px;
    display: grid;
    gap: 30px;

}

.home-section p {
    font-weight: normal;
    font-size: 17px;
    line-height: 130%;
    color: white;
}

.home-section h1 {
    font-weight: bold;
    font-size: 60px;
    color: white;
}

/* status text */
.home-section .status-active {
    font-size: 13px;
    font-weight: 600px;
    margin-left: 28px;
    padding: 7px;
    text-align: left;
    top: 0;
    width: 100%;
    color: white;
    position: absolute;
}

/* status indicator, green circle */
.home-section .indicator-active {
    border-radius: 100%;
    height: 12px;
    margin: 8px 8px 8px 10px;
    width: 12px;
    color: green;
    background-color: green;
}

/*container for status */
.home-section .green-container {
    border-radius: 7px;
    height: 30px;
    position: relative;
    background-color: rgba(0,127,0,0.3);
}


@media (max-width: 650px) {
    .home-section h1 {
        font-size: 34px;
    }
    .home-section {
        display: grid;
        grid-template-columns: 1fr;
    }
    .profile {
        width: 125px;
        padding-top: 100px;
        padding-bottom: 100px;
        margin-right: auto;
    }

}


/*
.home-section .circle {
    position: absolute;
    margin-left: 50%;
    background: lightgreen;
    border-radius: 50%;
    width: 350px;
    height: 350px;
}


@media (max-width: 450px) {
    .home-section {
        grid-template-columns: auto;
        padding: 150px 20px 250px;
        gap: 60px;
    }
  }

  */

@keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; transform: translateY(-10px); filter: blur(5px); }
    to   {opacity: 1; transform: translateY(0px); filter: blur(0px)}
}